import { RouterProvider, createBrowserRouter } from "react-router-dom";
import PatentDetails from "./components/PatentsDetails"; // Non-lazy loaded component
import PatentContextProvider from "./context/PatentContextProvider";
import React, { Suspense, useEffect, useState, useRef } from "react";
import Claims from "./components/Claims";
import Leadership from "./components/About/Leadership";
import Company from "./components/About/Company";
import Alert from "./components/About/Alert";
import ContactUs from "./components/ContactUs";
// Lazy load PatentIntro component
const PatentIntro = React.lazy(() => import("./components/PatentIntro"));

function App() {
  const [isAboutOpen, setIsAboutOpen] = useState(false); // Renamed state
  const dropdownRef = useRef(null); // Reference for dropdown container
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsAboutOpen(false); // Close the dropdown
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Suspense
          fallback={
            <div className="w-full h-screen bg-black flex items-center justify-center"></div>
          }
        >
          <PatentIntro /> {/* Lazy-loaded component */}
        </Suspense>
      ),
    },
    {
      path: "/patent-details/:patentId",
      element: <PatentDetails />,
    },
    {
      path: "/claims",
      element: <Claims />,
    },
    {
      path: "/company",
      element: <Company />,
    },
    {
      path: "/leadership",
      element: <Leadership />,
    },
    {
      path: "/alert-system",
      element: <Alert />,
    },
    {
      path: "/contact",
      element: <ContactUs />,
    },
  ]);

  return (
    <div className="App overflow-hidden">
      <PatentContextProvider>
        <RouterProvider router={router} />
      </PatentContextProvider>
    </div>
  );
}

export default App;
