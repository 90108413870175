export const bgImageWorkText = {
  title: "It's About the Right People",
  description:
    "We seek to work with the right talent,right leadership,the right management",
};
export const bgImageOfficeText = {
  title: "How Can you Reach Your Customers?",
  description:
    "OHanlonIP, LLC, provides a Mobile Marketing Suite of Tools that are effective in reaching your market.",
};

export const executives = [
  {
    name: "Ryan Wishnow-COO",
    experience:
      "Ryan Wishnow is a technical consultant and software architect with over ten years of experience delivering innovative software solutions across many vertical markets. His experience ranges from designing and managing large projects to hands-on technical implementation for fortune fifty companies and small businesses alike. He graduated from Texas State University - San Marcos with a degree in computer science and a minor in business administration.",
  },
  {
    name: "Michael Fossenier-General Counsel",
    experience:
      "Mr. Fossenier is a Company co-founder, having been involved in the OHanlonIP, LLC Mobile Solutions entity development since its inception in its original iteration in 2007, and now with OHanlonIP, LLC, (AT). Mr. Fossenier has been licensed to practice law since October, 1988, during which time he has acted in the arenas of commercial contracting, litigation and general civil matters. Michael had a BA degree from Mesa University and His Juris Doctorate from South Dakota College of law.",
  },
  {
    name: "Edson Hutchinson-CSO/CRO",
    experience:
      "Mr. Hutchinson is the Majority Member/co- founder of OHanlonIP, LLC Mobile Solutions and OHanlonIP, LLC, (AT). His business affiliations include being the Managing Member of Texas Pacific, LLC, Consultants/ Private Equity, Malaga, LLC, Hotels /Management and Indoor Entertainment Group, Amusement Rides Industry. He graduated with a BSBA degree in Finance/Real Estate from the University of Denver.",
  },
  {
    name: "Jared Petsche-CMO",
    experience:
      "Mr. Petsche has worked in the film/media industry, state government and with many of Denver’s non-profits for the last decade. He has also been involved with the Colorado Film & Video Association, and the AD Club Colorado, a local chapter of the American Advertising Federation. His career has been punctuated with several awards including a Pollie award for his political work along with several Emmy nominations for TV production. Jared currently sits on two committees at Open Media Foundation, Public Relations and Grant Writing. Jared ha degrees from Colorado State University, BA in Philosophy and minor studies in History, Religion and Entrepreneurship",
  },
  {
    name: "Jeffry A. Finn- Patent, IP and Technology Attorney",
    experience:
      "Jeff Finn is a seasoned technology lawyer serving start-up, emerging growth and multinational corporations. Jeff also advises on all aspects of IP matters, including drafting and negotiating licensing, IT, software and confidentiality agreements, M&A deals and public offerings, IP portfolio sales, product rollouts, and bankruptcies. Prior to starting his firm, Jeff was a partner at Sidley Austin, LLP and Katten Muchin Rosenman, LLP. Mr. Finn has degrees in BS, Electrical Engineering, University of Illinois and JD, University of Southern California Gould School of Law.",
  },
];

export const companyData = {
  title: "OHanlonIP, LLC, is a Mobile Marketing Technology Company",
  services:
    "We provide a turnkey Software as a Service text messaging platform to the Mobile Text Message Marketing Industry. Our tool possesses most all modern day features that are compulsory for a Marketing Team to reach your customers.",
  features: [
    {
      featureName: "",
      featureInfo: [
        "OHanlonIP, LLC, is a US  Company that controls powerful and far reaching intellectual property that impacts the text notification industry. As an SMS/MMS Service Provider, our Mobile Messaging MOTOM ™ Software Platform driven by our patented technology will allow businesses to send TCPA Compliant Text Messages to their clients.",
      ],
    },
    {
      featureName: "",
      featureInfo: [
        "The original parent company for OHanlonIP, LLC, is a licensee of OHanlonIP, LLC. Founded in 2005, OHanlonIP, LLC owns all rights to US Patent #7,643,821 (the '821 patent), the patent that solved the technological shortcomings of the state of the art, thereby enabling advertisers to push rich digital content (e.g., pictures, audio, and video) to mobile devices in real-time. The patented technology enabled the mobile ad industry to become what it is today.",
      ],
    },
  ],
};
export const faqsAlert = [
  {
    question: "Is there anything unique about your platform?",
    answer:
      "Our platform contains most of the modern features found in SMS and Text Messaging Platforms. What makes our platform unique is that we have our own in-house code customization team. What this means for you is that in most cases we can create custom features and add it into your control panel. Additionally, we strive to be a legally compliant technology company so we have certain licensing agreements with technology providers to ensure you are complying with the laws. This compliance extends to even certain patents that we are aware of that are issued by the United States Patent and Trademark Office. For more information click on our About Us page.",
  },
  {
    question: "Is there a demonstration where I can `test drive` the platform?",
    answer:
      "Absolutely. Please click the Contact Us page and simply let us know you would like to have a demonstration. Someone will contact you within the next business day and set up your demo panel.",
  },
  {
    question:
      "I'm convinced. I would like to change my provider over to you, can you import my contacts?",
    answer:
      "Not only will we be able to migrate your contacts over, if your current provider grants us access we can migrate in most cases your existing messages. We have a dedicated team of technoloy professionals who will move everything over from your current provider.",
  },
];

export const alertNotificationList = [
  "‘Your Best Partner in Employee Safety’",
  "Protect Your People...",
  "Protect Your Community...",
  "Protect Your Business...",
  "Protect Your Responders...",
];

export const mainNavlinks = ["Home", "Contant", "About"];
