import React from "react";

const Loader = () => {
  return (
    <div className="flex items-center justify-center space-x-1 mt-4">
      <div className="w-2.5 h-2.5 bg-gray-400 rounded-full animate-bounce"></div>
      <div className="w-2.5 h-2.5 bg-gray-400 rounded-full animate-bounce delay-150"></div>
      <div className="w-2.5 h-2.5 bg-gray-400 rounded-full animate-bounce delay-300"></div>
    </div>
  );
};

export default Loader;
