import React, { useState } from "react";
import axios from "axios";

function ViolationModal({ claim, isModalOpen, setIsModalOpen }) {
  const [businessSolution, setBusinessSolution] = useState("");
  const [violationResult, setViolationResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
 
  const checkViolations = async () => {
    if (!businessSolution) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await axios.post(`${process.env.REACT_APP_API_PATH}/checkViolations`,
        { businessSolution: businessSolution, claim: claim },
        {
          headers: {
            'Content-Type': 'application/json',
          },

        }
      );
      console.log('result', result)
      const analysisResult =
        result.data.response.candidates[0].content.parts[0].text;
      setViolationResult(analysisResult);
    } catch (error) {
    } finally {
      setIsLoading(false);
      setBusinessSolution("");
    }
  };


  return (
    <>
      {isModalOpen && (
        <div className="bg-gray-100 flex items-center justify-center h-screen">
          {/* Modal */}
          {isModalOpen && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-start justify-center z-50 pt-20"
              onClick={(e) => {
                // Close modal if clicked outside the modal container
                if (e.target.id === "modalOverlay") setIsModalOpen(false);
                setViolationResult(null);
              }}
              id="modalOverlay"
            >
              {/* Modal Container */}
              <div className="bg-white rounded-lg shadow-lg w-3/4 p-6 relative  max-h-[90vh] overflow-y-auto">
                <button
                  onClick={() => {
                    setIsModalOpen(false);
                    setViolationResult(null);
                  }}
                  className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  ✖
                </button>

                <div className="mb-4">
                  <h2 className="text-xl font-semibold text-gray-800 overflow-auto">
                    Enter Details To Check Violations
                  </h2>
                </div>
                {/* Modal Body */}
                <div className="mb-4">
                  <label
                    htmlFor="inputField"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  ></label>
                  <input
                    type="text"
                    id="inputField"
                    value={businessSolution}
                    onChange={(e) => setBusinessSolution(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="Enter Your Business Solution Here or a website url..."
                  />
                </div>
                {/* Modal Footer */}
                <div className="flex justify-end">
                  <button
                    className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    onClick={checkViolations}
                  >
                    Check Violations
                  </button>
                </div>
                {isLoading && (
                  <div className="flex justify-center items-center">
                    <span className="w-12 h-12 rounded-full animate-spin border-2 border-solid border-indigo-500 border-t-transparent"></span>
                    <span className="pl-5 text-2xl pt-2">
                      Checking for violations.....
                    </span>
                  </div>
                )}
                {!isLoading && violationResult && (
                  <div className="mt-4 p-4 bg-gray-100 rounded-lg shadow-lg">
                    {violationResult}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ViolationModal;
