export default function MainContent({ content }) {
  return (
    <div className=" bg-white py-10">
      <div className="w-11/12 m-auto  text-center ">
        <h2 className="text-[#01a4e4] text-4xl  font-bold pb-8">
          {content.title}
        </h2>
        <p className="pb-8 text-[#3b4455] text-2xl pl-2">{content.services}</p>
        {content.features?.map((feature) => (
          <div className=" text-lg">
            {feature.featureName && (
              <p className="pb-8">{feature.featureName}</p>
            )}
            <p className="pb-8">{feature.featureInfo}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
