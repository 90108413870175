import MainNavbar from "./MainNavbar";

export default function Hero({ bgImage, imageText }) {
  console.log(bgImage, "img");
  return (
    <div>
      <MainNavbar />

      {bgImage ? (
        <div
          className={` relative pl-8 py-40 text-center md:p-40 bg-cover bg-center text-white bg-gradient-to-b from-black/50 to-black/80`}
          style={{
            backgroundImage: `
          url(${bgImage})`,
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <div className="flex justify-center items-center flex-col relative z-10   ">
            <h3 className="text-5xl pb-8 font-bold">{imageText.title}</h3>
            <p className="text-2xl px-5">{imageText.description}</p>
          </div>
        </div>
      ) : (
        <div className="bg-black p-40 text-white">
          <div className="flex justify-center items-center">
            <h3 className="text-5xl pb-8 font-bold">{imageText?.title}</h3>
            <p className="text-2xl">{imageText?.description}</p>
          </div>
        </div>
      )}
    </div>
  );
}
