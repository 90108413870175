import React, { useState, useEffect } from "react";
import data from "../data/patents.json";
import { createContext } from "react";
import { patentsInfo } from "../constants/PatentsData";
export const PatentContext = createContext();
export default function PatentContextProvider({ children }) {
  const [patentsData, setPatentsData] = useState(data.patents);
  const [selectedPatent, setSelectedPatent] = useState(data?.patents[0]);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(() => {
    const checkActiveLink = localStorage.getItem("activelink");
    try {
      return checkActiveLink ? JSON.parse(checkActiveLink) : null;
    } catch (error) {
      return null;
    }
  });
  const [active, setActive] = useState(() => {
    const checkActivePatent = localStorage.getItem("activePatent");
    try {
      return checkActivePatent ? JSON.parse(checkActivePatent) : null;
    } catch (error) {
      return null;
    }
  });
  const [animate, setAnimate] = useState(() => {
    const checkAnimation = localStorage.getItem("animate");
    return checkAnimation ? JSON.parse(checkAnimation) : true;
  });
  const [playMusic, setPlayMusic] = useState(() => {
    const checkMusicPlay = localStorage.getItem("playMusic");
    return checkMusicPlay ? JSON.parse(checkMusicPlay) : false;
  });
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState("");
  // Video State
  const [videoCurrentTime, setVideoCurrentTime] = useState(
    localStorage.getItem("videoCurrentTime") || 0
  );

  //saving video time//
  useEffect(() => {
    localStorage.setItem("videoCurrentTime", videoCurrentTime);
  }, [videoCurrentTime]);

  useEffect(() => {
    setPatentsData(data.patents);
  }, []);

  const handlePatentClick = (patentId) => {
    setActive(patentId);
    patentSelected(patentId);
    localStorage.setItem("activePatent", JSON.stringify(patentId));
    setMenuOpen(false);
  };

  const patentSelected = (patentId) => {
    const choosenPatent = patentsData.find(
      (patent) => patent.patentId.replace(/[\s,]/g, "") === patentId
    );
    if (choosenPatent) {
      setSelectedPatent(choosenPatent);
    }
  };
  const handleLinkClick = (path) => {
    setActiveLink(path);
    localStorage.setItem("activelink", JSON.stringify(path));
  };

  return (
    <PatentContext.Provider
      value={{
        patentsData,
        setPatentsData,
        isLoading,
        setLoading,
        error,
        setError,
        selectedPatent,
        patentSelected,
        setVideoCurrentTime,
        audioPlayed,
        setAudioPlayed,
        playMusic,
        setPlayMusic,
        animate,
        setAnimate,
        active,
        setActive,
        handlePatentClick,
        menuOpen,
        setMenuOpen,
        handleLinkClick,
        activeLink,
        setActiveLink,
      }}
    >
      {children}
    </PatentContext.Provider>
  );
}
