import Hero from "../Hero";
import OfficeBg from "../../assets/images/office.jpg";
import { bgImageOfficeText } from "../../constants/constants";
import ContactUs from "../ContactUs";
import { companyData } from "../../constants/constants";
import MainContent from "../MainContent";

export default function Company() {
  return (
    <>
      <Hero bgImage={OfficeBg} imageText={bgImageOfficeText} />
      <MainContent content={companyData} />
      <ContactUs />
    </>
  );
}
