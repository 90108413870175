import FAQS from "../Faqs";
import Hero from "../Hero";
import ContactUs from "../ContactUs";
import { alertNotificationList } from "../../constants/constants";
export default function Alert() {
  return (
    <>
      <Hero bgImage="" imageText={{ title: "Alert System" }} />

      <div className="h-screen overflow-y-auto bg-white">
        <div>
          {/* <Hero bgImage="" imageText={{ title: "Alert System" }} /> */}
          <main className=" m-auto w-11/12 pb-7 pl-6">
            <div className="py-5">
              <h2 className="text-4xl font-bold text-[#01a4e4] pb-3">About:</h2>
              <h3 className="text-[#3b4455] text-3xl">OHanlonIP, LLC,</h3>
            </div>
            <div className="text-[#3b4455] text-xl">
              <p className="pb-7">
                OHanlonIP, LLC, provides solutions for mass alert notifications
                that connect public safety, municipalities, schools, hospitals,
                businesses and the communities they serve. For important or
                time-sensitive message, OHanlonIP, LLC allows you to engage in
                real-time, two-way interactions with any size audience using our
                text alert system.
              </p>
              <p className="pb-7">
                OHanlonIP, LLC, platform enables important data sharing between
                the key stakeholders in any crises - From the people on-site
                providing real-time status updates to first responders arriving
                on scene with the resources and information they need to act
                faster.
              </p>
              <p className="pb-7">
                OHanlonIP, LLC's, communication and collaboration tools are
                capable of delivering multi-channel critical notifications and
                alerts in emergency situations that threaten public safety or to
                support business continuity and employee safety initiatives.
              </p>
              <p className="pb-7">
                All of OHanlonIP, LLC,’s software solutions are easy-to-deploy,
                easy-to-use, and have been built specifically to support the
                exchange of critical information.
              </p>
              <p className="pb-7">
                The OHanlonIP, LLC, Alert System was designed for use in
                situations such as severe weather events, evacuations, safety
                hazards, security threats, IT/Telecom disruptions, business and
                employee notifications.
              </p>
            </div>
            <div className="pb-7">
              <h2 className="text-4xl font-bold text-[#01a4e4] pb-7">
                Alert Notification System:
              </h2>
              <h3 className="text-[#3b4455] text-xl font-bold pb-9">
                MOTOM ™ is:
              </h3>
              <ul className="pl-8  text-[#3B4455]">
                {alertNotificationList.map((notification) => (
                  <li className="list-disc text-lg/[30px]">{notification}</li>
                ))}
              </ul>
            </div>
            <div className="text-xl">
              <h4 className="text-[#3b4455] font-bold pb-5">
                Expect Reliability From Your Emergency Alert Notification
                System...
              </h4>
              <p className="pb-7">
                Whether you’re reaching out to 100 or 10,000 people, you need a
                reliable, quick, and modern alert system that keeps everyone
                connected during critical events.
              </p>
              <p className="pb-7">
                The OHanlonIP, LLC, Platform provides alert notifications to Any
                Device, Anywhere, Enterprise Security, Two-Way Messaging,
                Multi-Channel Delivery and Reporting and Insights to improve the
                processes of how your organization handles an event.
              </p>
              <p className="pb-7">
                OHanlonIP, LLC,’s Alert System delivers comprehensive, flexible
                and easy-to-use solutions to alert residents during crises and
                major incidents, to keep them safe.
              </p>
            </div>
            <div className="lg:w-2/5  w-5/6">
              <h2 className="text-4xl font-bold text-[#01a4e4] pb-7">
                Flexible Pricing Plans:
              </h2>
              <p className="text-[#3b4455] text-xl pb-7">
                OHanlonIP, LLC, offers Custom Pricing for your Emergency
                Communications needs...... Pricing is based on audience size and
                expected usage...... Fill out the form to get Custom Pricing....
              </p>
            </div>

            <form class="max-w-md bg-[#01a4e4] p-7 ">
              <div class="mb-5">
                <label
                  for="firstName"
                  class="block mb-2 text-lg font-medium text-white dark:text-white"
                >
                  FirstName
                </label>
                <input
                  type="password"
                  id="password"
                  class="bg-gray-50 border border-gray-300 text-white text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Your First Name"
                  required
                />
              </div>
              <div class="mb-5">
                <label
                  for="firstName"
                  class="block mb-2 text-lg font-medium text-white dark:text-white"
                >
                  LastName
                </label>
                <input
                  type="password"
                  id="password"
                  class="bg-gray-50 border border-gray-300 text-white text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Your Last Name"
                  required
                />
              </div>
              <div class="mb-5">
                <label
                  for="email"
                  class="block mb-2 text-lg font-medium text-white dark:text-white"
                >
                  Email address
                </label>
                <input
                  type="email"
                  id="email"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Valid Email"
                  required
                />
                <small className="text-white py-5">
                  We'll never share your email with anyone else.
                </small>
              </div>

              <button
                type="submit"
                class="text-white bg-red-700 hover:bg-red-800   rounded-lg text-sm font-bold w-full sm:w-auto px-5 py-2.5 text-center md:px-16 md:py-5"
              >
                GET PRICING
              </button>
            </form>
            <p className="py-7 md:w-7/12 w-5/6 text-[#3b4455] text-xl">
              Simple Pricing:OHanlonIP, LLC makes pricing easy with a Custom
              Fixed Price that delivers you everything you need to achieve your
              communication needs.
            </p>
            <p className="pb-7 text-[#3b4455] text-xl">
              No Set Up Fees: Get started using our software without upfront
              costs. We have customers up-and-running within hours when needed,
              and no set up fee
            </p>
          </main>
          <FAQS />
          <ContactUs />
        </div>
      </div>
    </>
  );
}
