import logo from "../assets/images/logo4.png";
import React, { useContext, useState, useEffect, useRef } from "react";
import { PatentContext } from "../context/PatentContextProvider";
import { useNavigate, Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
export default function MainNavbar() {
  const [scroll, setScroll] = useState(false);

  const {
    setAnimate,
    setPlayMusic,
    active,
    setActive,
    handlePatentClick,
    menuOpen,
    setMenuOpen,
    activeLink,
    setActiveLink,
    handleLinkClick,
  } = useContext(PatentContext);
  const [isAboutOpen, setIsAboutOpen] = useState(false); // Renamed state
  const dropdownRef = useRef(null); // Reference for dropdown container
  const navigate = useNavigate();
  //functions//
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  function navigateToHomePage() {
    setPlayMusic(false);
    setAnimate(false);
    navigate("/");
    window.scrollTo(0, 0);
  }
  console.log(activeLink, "active link");

  //effects//
  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 130);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    setMenuOpen(false);
  }, []);

  return (
    <>
      <div
        className={`w-full transition-transform duration-300 ease-in-out z-20 font-bold ${
          scroll ? "bg-[#393e46] z-20 fixed top-0 " : "bg-black fixed "
        } md:px-5 lg:px-20 flex justify-between items-center`}
      >
        <div
          className={`' ${
            scroll ? "h-20" : "h-32"
          } relative  flex justify-center items-center py-2'`}
          style={{ cursor: "pointer" }}
        >
          <img
            src={logo}
            alt="logo"
            className={` ${
              scroll ? "w-[4.3rem] sm:pl-0 pl-2" : "w-[7.3rem] pl-2"
            } `}
            onClick={navigateToHomePage}
          />
        </div>
        <div className="lg:hidden flex items-center">
          <button onClick={toggleMenu} className="text-3xl mr-2">
            {menuOpen ? (
              <AiOutlineClose className="text-white" />
            ) : (
              <AiOutlineMenu className="text-white" />
            )}
          </button>
        </div>
        {menuOpen && (
          <ul
            className={` absolute  right-0 bg-gray-200 w-full   py-36  md:py-[4.5rem] flex gap-8 flex-col items-center justify-center z-50 lg:text-[20px] md:text-[16px] ${"top-32"}`}
          >
            <li>
              <Link to="/">Home</Link>
            </li>

            <li>
              <Link
                to="/company"
                onClick={() => handleLinkClick("/company")}
                className={`block px-4 py-2 text-sm ${
                  activeLink == "/company"
                    ? "text-red-700 bg-white"
                    : "text-gray-700 hover:bg-gray-100"
                }`}
              >
                About Us
              </Link>
            </li>

            <li>
              <Link
                to="/alert-system"
                className={`block px-4 py-2 text-sm ${
                  activeLink === "/alert-system"
                    ? "text-gray-700 bg-white"
                    : "text-gray-700 hover:bg-gray-100"
                }`}
              >
                Alert System
              </Link>
            </li>
          </ul>
        )}

        <ul className="hidden lg:flex text-white gap-5 text-2xl font-bold">
          <li>
            <Link
              to="/"
              onClick={() => handleLinkClick("/")}
              className={`block px-4 py-2 text-xl ${
                activeLink == "/" ? "border-b-2 bg-custom-gradient" : ""
              }`}
            >
              Home
            </Link>
          </li>

          <li>
            <Link
              to="/company"
              onClick={() => handleLinkClick("/company")}
              className={`block px-4 py-2 text-xl ${
                activeLink == "/company"
                  ? `${
                      scroll
                        ? "text-[#00A4E5]"
                        : "text-white border-b-2 border-white bg-custom-gradient py-2 px-4  transition delay-300 duration-300 ease-in-out"
                    }`
                  : `${scroll ? "text-white" : "text-white "}`
              }`}
            >
              About Us
            </Link>
          </li>

          <li>
            <Link
              to="/alert-system"
              onClick={() => handleLinkClick("/alert-system")}
              className={`block px-4 py-2 text-xl ${
                activeLink === "/alert-system"
                  ? `${
                      scroll
                        ? "text-[#00A4E5]"
                        : "text-white border-b-2 border-white bg-custom-gradient py-2 px-4  transition delay-300 duration-300 ease-in-out"
                    }`
                  : `${scroll ? "text-white" : "text-white "}`
              }`}
            >
              Alert System
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}
