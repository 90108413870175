import { executives } from "../../constants/constants";
import { useState } from "react";
export default function Executives() {
  const [executiveInfo, setExecutiveinfo] = useState(executives);
  return (
    <>
      {executiveInfo.map((person) => (
        <div className="p-7">
          <h2 className="text-[#3b4455] text-2xl pb-4">{person.name}</h2>

          <p>{person.experience}</p>
        </div>
      ))}
    </>
  );
}
