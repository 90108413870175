import { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import data from "../data/patents.json";
import { PatentContext } from "../context/PatentContextProvider";
import { GoogleGenerativeAI } from "@google/generative-ai";
import { useLocation } from "react-router-dom";
import ViolationModal from "./Modal/ViolationModal";
import Bot from "./ChattingBot";
import Navbar from "./Navbar";
import { HfInference } from "@huggingface/inference";
export default function Claims() {
  const { selectedPatent, active, setActive } = useContext(PatentContext);

  const location = useLocation();

  const { activePatent } = location.state ? location.state : selectedPatent;

  useEffect(() => {
    setActive(active);
    localStorage.setItem("activePatent", JSON.stringify(active));
  }, []);

  const hf = new HfInference("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");
  //gemini response//
  // const apiKey = process.env.REACT_APP_API_KEY;
  // const ttsKey = process.env.REACT_APP_TTS_KEY;
  // let genAI;
  // let model;
  // if (apiKey !== undefined) {
  //   genAI = new GoogleGenerativeAI(`${apiKey}`);
  //   model = genAI.getGenerativeModel({ model: "gemini-1.5-flash-8b" });
  // }
  const [claimIndex, setClaimIndex] = useState(0);
  const [voiceType, setVoiceType] = useState(null);
  const [enableChat, setEnableChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const audioSourceRef = useRef(null); // Ref to manage audio source
  const audioContextRef = useRef(null); // Ref to manage audio context
  const audioRef = useRef(null); 

  //functions//

  function handleNext() {
    if (
      (activePatent?.claims && claimIndex < activePatent.claims.length - 1) ||
      (selectedPatent?.claims && claimIndex < selectedPatent?.claims.length - 1)
    ) {
      setClaimIndex((prev) => prev + 1);
    }
  }

  function handlePrevious() {
    if (claimIndex > 0) {
      setClaimIndex((prev) => prev - 1);
    }
  }
  //chatbot
  const openChat = () => {
    setEnableChat(true);
  };
  const closeChat = () => {
    setEnableChat(false);
  };
  const generateSummary = async (languageStyle) => {
    if (!activePatent?.claims) return;
    setLoading(true);
    setVoiceType(languageStyle);

    // const prompt = `Analyze the provided claim and provide a short and precise summary like a  paragraph of a book in high english words spoken by people in United Kindom.Summary should be  different  for every  ${languageStyle}:\n\n${activePatent.claims[claimIndex]}\n\n.The summary should concisely capture the essence of claim specified,it should be precise and to the point (not too long keep it as short as possible with clear message).Do not include ${languageStyle} name in summary Do not include unnessecary numbers, unless qouting figures.`;

    try {
      // const result = await model.generateContent(prompt);
      const getResponse = await axios.post(`${process.env.REACT_APP_API_PATH}/generateSummary`,
        { languageStyle: languageStyle, activePatent: activePatent.claims[claimIndex] },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const generatedSummary = getResponse.data.response.candidates[0]?.content?.parts[0].text
        .replace(/[^\w\s]/g, "")
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
        .replace(/[^\w\s\']|_/g, "")
        .replace(/\s{2,}/g, "");
      setLoading(false);
      if (generatedSummary) {
        speakSummary(generatedSummary);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  async function speakSummary(text) {
    //4th option best till now //

    setLoading(true); // Start loading

    try {
      
      const response = await axios.post(`${process.env.REACT_APP_API_PATH}/generateSpeech`,
        { text: text },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'arraybuffer'
        }
      );
        const blob = new Blob([response.data], { type: 'audio/mpeg' });
        const url = URL.createObjectURL(blob);
       
  
        // Optional: Play the audio
      const audio = new Audio(url);
      
      audio.addEventListener('play', () => setAudioPlaying(true));
  
      // Store the instance in the ref
      audioRef.current = audio;
      // audio.load();
      // Play the audio
     await audio.play();
   
      if (audioRef.current) {
        audioRef.current.pause(); 
        audioRef.current.currentTime = 0; 
      }
      audioRef.current = new Audio(url);
      audioRef.current.play();
      setAudioPlaying(true)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause(); 
      audioRef.current.currentTime = 0; 
    }
    setAudioPlaying(false);
  };
  const previousDisabled = claimIndex === 0;
  const nextDisabled =
    activePatent?.claims && claimIndex === activePatent.claims.length - 1;

  return (
    <>
      {
        <ViolationModal
          claim={activePatent?.claims[claimIndex]}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      }

      <Navbar />
      <div className="flex flex-col justify-between overflow-y-auto  gap-2 pt-36 pb-16">
        <div className="bg-white py-3 md:py-7 px-5 w-[93%] mx-auto max-w-[1350px] rounded-lg">
          {location.state ? (
            <p>{data.patents[0].claims[claimIndex]}</p>
          ) : (
            <p>
              {activePatent?.claims
                ? activePatent?.claims[claimIndex]
                : data.patents[0].claims[claimIndex]}
            </p>
          )}

          <div className="flex  px-[-7px] pt-1 pb-40 md:px-2 justify-between lg:px-5 md:pt-5 md:pb-5 ">
            <button
              className={`${
                previousDisabled
                  ? "bg-gray-400 text-gray-200 cursor-not-allowed "
                  : "bg-[#00A4E5] "
              } p-3 text-white rounded-md`}
              onClick={handlePrevious}
              disabled={previousDisabled}
            >
              Previous
            </button>
            <button
              className={`${
                nextDisabled
                  ? "bg-gray-400 text-gray-200 cursor-not-allowed "
                  : "bg-[#00A4E5] "
              } p-3 text-white rounded-md`}
              onClick={handleNext}
              disabled={nextDisabled}
            >
              Next
            </button>
          </div>
        </div>

        <div className="flex justify-center m-auto w-3/4 max-w-md gap-2">
          <div className="fixed bottom-1 gap-2 flex flex-col lg:gap-5 md:mt-20  md:pt-8  md:w-[96%]  lg:flex  lg:flex-row  items-center lg:w-[92%]  justify-center">
            {/* Summary buttons */}
            <button
              className="bg-[#393e46]  w-[100%] px-3 py-2.5 text-white rounded-lg min-w-[200px]  md:w-2/3"
              onClick={(e) => {
                if (e.target.textContent === "Stop Summary") {
                  setVoiceType(null);
                  stopAudio();
                  return;
                }

                generateSummary("Legal");
              }}
            >
              {loading && voiceType === "Legal" ? (
                <p className="px-12">
                  <span class="w-3 h-3 border-2 border-t-transparent border-white rounded-full inline-block animate-spin mr-2"></span>
                  <span>Loading Voice.....</span>
                </p>
              ) : (
                <p>
                  {voiceType === "Legal" && audioPlaying
                    ? "Stop Summary"
                    : "Play Summary In Legal Voice"}
                </p>
              )}
            </button>
            <button
              className="bg-[#393e46] px-3 py-2.5  w-[100%] text-white rounded-lg  min-w-[200px] md:w-2/3"
              onClick={(e) => {
                if (e.target.textContent === "Stop Summary") {
                  setVoiceType(null);
                  stopAudio();
                  return;
                }

                generateSummary("Business");
              }}
            >
              {loading && voiceType === "Business" ? (
                <p className="px-12">
                  <span class="w-3 h-3 border-2 border-t-transparent border-white rounded-full inline-block animate-spin mr-2"></span>
                  <span>Loading Voice.....</span>
                </p>
              ) : (
                <p>
                  {voiceType === "Business" && audioPlaying
                    ? "Stop Summary"
                    : "Play Summary in Bussiness Voice"}
                </p>
              )}
            </button>
            <button
              className="bg-[#393e46] px-5 py-2.5 w-[100%] text-white rounded-lg min-w-[200px]  md:w-2/3"
              onClick={(e) => {
                if (e.target.textContent === "Stop Summary") {
                  setVoiceType(null);
                  stopAudio();
                  return;
                }

                generateSummary("10th Grade");
              }}
            >
              {loading && voiceType === "10th Grade" ? (
                <p className="px-12">
                  <span class="w-3 h-3 border-2 border-t-transparent border-white rounded-full inline-block animate-spin mr-2"></span>
                  <span>Loading Voice.....</span>
                </p>
              ) : (
                <p>
                  {voiceType === "10th Grade" && audioPlaying
                    ? "Stop Summary"
                    : "Play Summary in 10thGrade Voice"}
                </p>
              )}
            </button>
            <button
              className="bg-[#393e46] px-5 py-2.5  w-[100%] text-white rounded-lg min-w-[200px]  md:w-2/3"
              onClick={() => setIsModalOpen(true)}
            >
              Check Violations
            </button>
          </div>
        </div>
        {enableChat && <Bot closeChat={closeChat} />}
        <button
          className="fixed bottom-[74px] right-3  py-2 px-2 sm:right-5 sm:py-2 sm-px-2 md:right-10 md:bottom-36 md:px-3 md:py-3 md:text-2xl  text-xl z-10 cursor-pointer hover:bg-[#00000098] bg-[#00000068] text-white rounded-full lg:bottom-20"
          onClick={openChat}
        >
          <svg
            class="w-5 h-5 md:w-8 md:h-8"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z"></path>
            <path d="M8 12h.01"></path>
            <path d="M12 12h.01"></path>
            <path d="M16 12h.01"></path>
          </svg>
        </button>
      </div>
    </>
  );
}
