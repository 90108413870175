import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PatentContext } from "../context/PatentContextProvider";
import { BsCalendarDateFill } from "react-icons/bs";
import { FaUserTie } from "react-icons/fa6";
import { FaArrowUp } from "react-icons/fa6";
import data from "../data/patents.json";

// import { OpenAI } from "openai";
import Aos from "aos";
import "aos/dist/aos.css";
import Navbar from "./Navbar";
import Bot from "./ChattingBot";
import "./style/Patentdetails.css";

const PatentDetails = () => {
  const {
    selectedPatent,
    patentSelected,

    handlePatentClick,
  } = useContext(PatentContext);
  const { patentId } = useParams();

  const [showScrollButton, setShowScrollButton] = useState(false);

  const [voiceType, setVoiceType] = useState(null);
  const [enableChat, setEnableChat] = useState(false);
  const [pause, setPause] = useState(true);

  //effects//
  useEffect(() => {
    patentSelected(patentId);
    //setSummary(false);
    setVoiceType(null);
  }, [patentId]);
  useEffect(() => {
    //setSummary(false);
    setVoiceType(null);
  }, [selectedPatent]);

  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 130) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  //scrolling to top //
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowScrollButton(false);
  };
  //chatbot
  const openChat = () => {
    setEnableChat(true);
  };
  const closeChat = () => {
    setEnableChat(false);
  };
  //show  claims //
  const navigate = useNavigate();
  function showClaims() {
    handlePatentClick(selectedPatent.patentId.replace(/[\s,]/g, ""));

    navigate("/claims", {
      state: {
        activePatent: selectedPatent ? selectedPatent : data?.patents[0],
      },
    });
    window.scrollTo(0, 0);
  }

  return (
    <>
      <Navbar
        choosenPatent={selectedPatent}
        activePatentId={patentId}
        showScrollButton={showScrollButton}
      />

      <div id="top" className="relative " data-aos="fade-up">
        <div className="bg-black pt-36 pb-4">
          <div className="flex-col mb-10 px-14">
            <p
              className=" sm:text-3xl md:text-3xl lg:text-4xl font-bold text-center rounded-md text-white pb-3 font-roboto font-openfont"
              style={{ textShadow: "1px 0px 2px rgba(0,0,0,0.6)" }}
            >
              {selectedPatent?.title}
            </p>
            <p
              className="sm:text-lg md:text-xl  text-white text-center font-medium pb-8 font-openfont"
              style={{ textShadow: "1px 0px 2px rgba(0,0,0,0.6)" }}
            >
              {selectedPatent?.patentId}
            </p>
          </div>
        </div>
        <div className="relative right-0 left-0 w-[94%] px-5 mx-auto p-2 rounded-lg  bg-white lg:px-10">
          <div className="relative h-100vh w-full ">
            <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  gap-2 justify-between   font-semibold   my-4 sm:my-3 md:my-2 lg:my-4">
              <div className="flex  w-64 gap-4    items-center sm:justify-start lg:justify-center ">
                <span className="w-12 h-12  rounded-full text-center pl-3 pt-2.5 bg-white border border-black ">
                  <FaUserTie className="text-2xl font-extrabold " />
                </span>
                <div>
                  <p className="text-2xl">Inventor</p>
                  <p className="text-[0.8rem] text-[#3b4455]">
                    {selectedPatent?.inventor}
                  </p>
                </div>
              </div>

              <div className="flex  w-64 gap-4 items-center  sm:justify-start lg:justify-center ">
                <span className="w-12 h-12  rounded-full text-center pl-2 pt-2.5 bg-white border border-black ">
                  <svg
                    class="w-7 h-7"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z"></path>
                  </svg>
                </span>
                <div>
                  <p className="text-2xl">Patent ID</p>
                  <p className="tex5t-[0.8rem] text-[#3b4455]">
                    {selectedPatent?.patentId}
                  </p>
                </div>
              </div>

              <div className="flex  w-64 gap-4  items-center sm:py-2 sm:justify-start lg:justify-center ">
                <span className="w-12 h-12  rounded-full text-center pl-3 pt-2.5 bg-white border border-black ">
                  <BsCalendarDateFill className="text-2xl font-extrabold " />
                </span>
                <div>
                  <p className="text-2xl">File Date</p>
                  <p className="text-[0.8rem] text-[#3b4455]">
                    {selectedPatent?.fileDate}
                  </p>
                </div>
              </div>
              <div className="flex  w-64 gap-4  items-center sm:py-2 sm:justify-start lg:justify-center ">
                <span className="w-12 h-12  rounded-full text-center pl-3 pt-2.5 bg-white border border-black ">
                  <BsCalendarDateFill className="text-2xl font-extrabold " />
                </span>
                <div>
                  <p className="text-2xl">Issued Date</p>
                  <p className="text-[0.8rem] text-[#3b4455]">
                    {selectedPatent?.issued}
                  </p>
                </div>
              </div>
            </div>
            <p className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold leading-3 text-[#00A4E5] pt-4 pb-8 font-openfont  text-start">
              Abstract
            </p>
            <p className="text-md sm:text-lg leading-relaxed text-[#3b4455] text-[12px] font-roboto">
              {selectedPatent?.abstract}
            </p>
            <p className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold leading-3 text-[#00A4E5] py-8 font-openfont">
              Field Of Inventor
            </p>
            <p className="text-md sm:text-lg leading-relaxed text-[#3b4455] text-[12px] font-roboto">
              {selectedPatent.fieldOfInventor}
            </p>
            <div className="flex  pt-5 gap-4 items-center justify-center">
              <button
                className="bg-[#00A4E5] px-24 py-3 rounded-lg text-white font-semibold"
                onClick={showClaims}
              >
                View {selectedPatent?.claims?.length + " "}Claims
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-green-500 w-full">
        {showScrollButton && (
          <button
            className="fixed bottom-5  py-2 px-2 right-7 sm:py-2 sm-px-2 md:right-11 md:bottom-20 md:px-3 md:py-3 md:text-2xl  text-xl z-10 cursor-pointer hover:bg-[#00000098] bg-[#00000068] text-white rounded-full lg:bottom-12 lg:right-11 "
            onClick={scrollToTop}
          >
            <FaArrowUp />
          </button>
        )}
      </div>
      {enableChat && <Bot closeChat={closeChat} />}
      <button
        className="fixed bottom-20 right-7  py-2 px-2 sm:right-5 sm:py-2 sm-px-2 md:right-10 md:bottom-36 md:px-3 md:py-3 md:text-2xl  text-xl z-10 cursor-pointer hover:bg-[#00000098] bg-[#00000068] text-white rounded-full lg:bottom-28"
        onClick={openChat}
      >
        <svg
          class="w-5 h-5 md:w-8 md:h-8"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z"></path>
          <path d="M8 12h.01"></path>
          <path d="M12 12h.01"></path>
          <path d="M16 12h.01"></path>
        </svg>
      </button>
    </>
  );
};

export default PatentDetails;
