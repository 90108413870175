import { bgImageWorkText } from "../../constants/constants";
import bgPeople from "../../assets/images/people.jpg";
import Hero from "../Hero";
import Executives from "./Executives";
import ContactUs from "../ContactUs";
export default function Leadership() {
  return (
    <div className="bg-white">
      <Hero bgImage={bgPeople} imageText={bgImageWorkText} />
      <Executives />
      <ContactUs />
    </div>
  );
}
