import { faqsAlert } from "../constants/constants";
import QuestionsAnswers from "./QuestionAnswers";
export default function FAQS() {
  return (
    <div>
      <div className="flex flex-col m-auto w-[95%] md:flex-row gap-7">
        <div className="md:w-1/3 md:block w-5/6 flex justify-center items-center flex-col">
          <h2 className="text-4xl text-[#01a4e4] pb-6 px-6 font-bold">
            Please Contact us for a Demonstration
          </h2>
          <p className="pb-6 text-center text-[#3b4455] text-2xl px-4">
            We currently provide technical support via support tickets to our
            customers. General and/or Legal Inquiries will normally be responded
            through our Webform within 24-48 hours.
          </p>
          <p className="text-[#868e96] text-xl md:p-0 pl-5">
            Here are answers to some of the most common questions we get about
            the Messaging Platform
          </p>
        </div>
        <div className="flex-1">
          <QuestionsAnswers faqs={faqsAlert} />
        </div>
      </div>
    </div>
  );
}
